import { useEffect, useState } from '@wordpress/element';
import { replaceNordicCharacters } from '../utils';
export default function Block({ regionText, linkGap, isAdmin }) {
	const data = goland ? goland.regions : null;
	const [regions, setRegions] = useState(null);

	// Replace link tags with divs in the editor
	const LinkTag = isAdmin ? 'div' : 'a';

	useEffect(() => {
		if (data) {
			const sortedRegions = data.sort((a, b) => {
				return a.localeCompare(b);
			});
			setRegions([...new Set(sortedRegions)]);
		}
	}, []);


	const links = regions && regions.map((region, index) => {
		let uppercaseRegion = region.charAt(0).toUpperCase() + region.slice(1);
		return <LinkTag key={index} className='region-link' style={{marginBottom: `${linkGap}px`}} href={`${goland.pages.ad.listing}${replaceNordicCharacters(region)}`}>{regionText + uppercaseRegion}</LinkTag>
	})

	if (links) {
		return (
			<div className="footer-links-container">
				{links}
			</div>
		)
	}
	return "";
}